import React, { useRef } from 'react';
import { Form, Select, Spin } from 'antd';
import { SenderIdService } from "../services/SenderIdService";

const debounce = (cb, timeout = 300, _idle = true, _args) => (...args) => {
    if (_idle) {
        setTimeout(() => {
            cb(..._args);
            _idle = true;
        }, timeout);

        _idle = false;
    }

    _args = [...args];
};

export const DebounceSelectForSenderId = ({ query, debounceTimeout = 500, ...props }) => {
    const fetchOptions = (value) => SenderIdService.fetchRecordSenderId({senderId :value })
        .then(data => {
            console.log("sender Id:", data.senderIds);
            return [
                ...data.senderIds.map((sender)   => ({
                    label: `${sender.senderId}`,
                    value: sender.senderId,
                })),
            ];
        });

    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    const ref = useRef();

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
        <Form.Item name="senderId" rules={[{ required: false }]}>
            <Select
                placeholder="Select a senderId"
                showSearch
                filterOption={true}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                {...props}
                options={options}
            />
        </Form.Item>
    );
}
