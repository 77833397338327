import React, {useRef} from 'react';
import {Form, Select, Spin} from 'antd';
// import {GroupService} from "../services/ContactBook/GroupService";
import {ReportsService} from "../services/ReportsService";
import {RatePlanService} from "../services/RatePlanService";
import {PartyService} from "../services/PartyService";


const debounce = (cb, timeout = 300, _idle = true, _args) => (...args) => {
    if (_idle) {
        setTimeout(() => {
            cb(..._args);
            _idle = true;
        }, timeout);

        _idle = false;
    }

    _args = [...args];
};

export const DebounceSelectForRatePlanPartyId = ({ query, debounceTimeout = 500, ...props }) => {
    const fetchOptions = (partyId) =>   PartyService.fetchRecordsAllParty({ partyId: partyId })
        .then(data => {
            console.log("rate data:", data.parties);
            return [
                ...data.parties.map((r) => ({
                    label: `${r.partyId}-${r.name}`,
                    value: `${r.partyId},${r.name}`,
                })),
            ];
        });

    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (partyId) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(partyId).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
        <Form.Item name="partyId" rules={[{ required: false }]} >
            <Select
                placeholder="Select a Party id"
                showSearch
                filterOption={true}
                onSearch={(value) => debounceFetcher(value, props.name)}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                {...props}
                options={options}
            />
        </Form.Item>
    );
}
