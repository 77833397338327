import React, {useRef} from 'react';
import { Col, Row, Layout, Breadcrumb, Space, Button,Avatar } from 'antd';
import { TopMenu } from "./TopMenu";
import { MainMenu } from './MainMenu';
import getAllConfig from '../config/main';
import {Link} from "react-router-dom";

const { Header, Sider } = Layout;


export const AppLayout = ({ render: PageContent, actor, routeComponent }) => {
    const [appState, sendApp] = [actor.getSnapshot(), actor.send];

    // return <Layout>
    //     {appState.matches("login") || <Header className="header" style={{paddingLeft:'0px', paddingRight:'5px'}}>
    //         <Row style={{marginBottom:10}}>
    //             <Col xs={10} sm={12} className="logo">
    //                 <Link to='/' >
    //                     <div style={{ backgroundColor: "white", paddingLeft: "15px", paddingRight: "10px", display: "inline-block" }}>
    //                         <img src={getAllConfig.logo} />
    //                     </div>
    //                 </Link>
    //                 &nbsp;
    //                 <Link to='/' style={{color:"white"}}>SMS-Portal Admin</Link>
    //             </Col>
    //             <Col xs={14} sm={12} className="menu">
    //                 <TopMenu actor={actor} />
    //             </Col>
    //         </Row>
    //     </Header>}
    //     <Layout>
    //         {appState.matches("login") || <Sider width={240} className="site-layout-background">
    //             <MainMenu actor={actor} />
    //         </Sider>}
    //         <Layout style={{ padding: "2px" }}>
    //             <PageContent actor={appState.context.actor} />
    //             {routeComponent}
    //         </Layout>
    //     </Layout>
    // </Layout>;





    return [
        <Layout style={{ padding: "24px" }}>
            <PageContent actor={appState.context.actor} />
        </Layout>,
        <Layout>
            <Header className="header" style={{paddingLeft:'0px',paddingRight:'0px'}}>
                <Row style={{marginBottom:10}}>
                    <Col xs={10} sm={4} className="logo" >
                        <div style={{ backgroundColor: "white", paddingLeft: "15px", paddingRight: "10px", display: "inline-block" }}>
                            <img src={getAllConfig.logo} />
                        </div>
                        &nbsp;
                        <Link to="/" style={{color:'white'}}><span>SMS-Portal Admin</span></Link>
                    </Col>
                    <Col xs={14} sm={20} className="menu"><TopMenu actor={actor} /></Col>
                </Row>
            </Header>
            <Layout>
                <Sider width={240} className="site-layout-background">
                    <MainMenu actor={actor} />
                </Sider>
                <Layout style={{ padding: "2px" }}>
                    {false && window.location.href.match(/^(?:.*?:\/\/)?(?:[^\/]+)\/?$/)
                        ? <PageContent actor={appState.context.actor}/> : null}
                    {routeComponent}
                </Layout>
            </Layout>
        </Layout>
    ][appState.matches("login") ? 0 : 1];
};
