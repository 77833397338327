import { useActor } from "@xstate/react";
import { Menu } from "antd";
import {useNavigate} from "react-router-dom";

export function TopMenu({ actor }) {
    const [appState, sendApp] = [actor.getSnapshot(), actor.send];
    const navigate = useNavigate();
    return <Menu mode="horizontal" className="menu" selectedKeys={[appState.value]} style={{direction: "rtl"}}>
        <Menu.Item
            key="logout"
            onClick={() => {
                sendApp({ type: 'LOGOUT' });
                navigate('/');
            }}
        >
            Logout
        </Menu.Item>
    </Menu>;
}
