import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    Modal,
    Pagination,
    Row,
    Select,
    Space,
    Table, Tag,
    Typography
} from "antd";
import {AccountingService} from "../services/AccountingService";
import {countries} from "countries-list";
import {PlusCircleFilled} from "@ant-design/icons";
import dayjs from "dayjs";
import {TopupParty} from "./TopupParty";
import {ModalTitleBar} from "./ModalTitleBar";
import moment from "moment";
import {PartyService} from "../services/PartyService";


const SearchForm = ({ onSearch }) => {
    const [searchForm] = Form.useForm();

    const performSearch = () => {
        const formData = searchForm.getFieldsValue();

        ["startDate", "endDate"].forEach((n, i) => {
            const date = formData[n];
            formData[n] = date ? dayjs(date).add(i, "day").format("YYYY-MM-DD HH:mm:ss") : null;

            if (formData[n] === null) {
                delete formData[n];
            }
        });

        const queryData = ["partyId", "date","amount"].reduce((acc, v) => {
            const field = v;
            const fieldOp = `${field.replace("_value", "")}_op`;
            const fieldValue = (acc[field] || "").trim();

            if (fieldValue === "") {
                delete acc[field];
                delete acc[fieldOp];
            } else {
                acc[field] = fieldValue;
            }

            return acc;
        }, formData);

        // queryData = { userName: "value", userName_op: "contains", phoneNumber: "value", phoneNumber_op: "contains" };
        onSearch(Object.keys(queryData).length ? queryData : null);
    };

    return (<>
        <Form
            form={searchForm}
            labelCol={{span: 18}}
            wrapperCol={{ span: 23}}
            labelAlign="left"
            initialValues={{ startDate: moment().subtract(1, 'days'), endDate:moment(new Date()) }}
        >
            <Form.Item name="partyId" label="Party ID" children={<Input />} style={{display:"inline-block",marginBottom:'0px'}} />
            <Form.Item name="startDate" label="From Date" style={{display: 'inline-block', marginBottom: '0px'}} children={<DatePicker showTime use12Hours={true} format="YYYY-MM-DD HH:mm:ss" />}/>
            <Form.Item name="startDate_op" initialValue={"greaterThanEqualTo"} hidden children={<Input/>}/>
            <Form.Item name="endDate" label="To Date" style={{display: 'inline-block', marginBottom: '0px'}} children={<DatePicker showTime use12Hours={true} format="YYYY-MM-DD HH:mm:ss" />}/>
            <Form.Item name="endDate_op" initialValue={"lessThanEqualTo"} hidden children={<Input/>}/>

            <Form.Item style={{display:'inline-block', marginBottom:0}} label=" " colon={false}>
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={performSearch}
                    children={"Search"}
                />
            </Form.Item>
        </Form>
    </>);
};

const DataView = ({ paymentSummary, viewPage, viewLimit,partyId, onEdit }) => {

    const CurrentBalanceFetcher = ({ partyId }) => {
        const [balance, setBalance] = useState('0.00');

        useEffect(() => {
            const fetchBalanceData = async () => {
                try {
                    const data = await PartyService.fetchBalance({ partyId });
                    const balanceValue = parseFloat(data.balance).toFixed(2);
                    setBalance(balanceValue);
                } catch (error) {
                    console.error('Error fetching balance:', error);
                    setBalance('0.00');
                }
            };

            fetchBalanceData();
        }, [partyId]);

        return <span>{balance !== null ? balance : 'Loading...'}</span>;
    };

    return (<> {
        partyId ? (<Table
                style={{marginLeft:6}}
                size="small"
                dataSource={paymentSummary}
                // rowKey={}
                locale={{ emptyText: paymentSummary === null ? "E" : "No Data" }}
                pagination={false}
            >
                <Table.Column
                    dataIndex={undefined}
                    title={"#"}
                    render={(_, __, i) => (viewPage - 1) * viewLimit + (++i)}
                />
                <Table.Column title="Party ID" dataIndex={"partyId"} />
                <Table.Column title="Payment Amount" dataIndex={"amount"} />
                {/*<Table.Column title="Start Date" dataIndex={"start"} />*/}
                <Table.Column
                    title="Payment Date"
                    dataIndex="start"
                    render={value => dayjs(value).format("MMM D, YYYY - hh:mm A")}
                />
                {/*<Table.Column title="Date" dataIndex={"date"} render={value => dayjs(value).format("MMM D, YYYY - hh:mm A")} />*/}
            </Table>
        ) : (<Table
            style={{marginLeft:6}}
            size="small"
            dataSource={paymentSummary}
            // rowKey={}
            locale={{ emptyText: paymentSummary === null ? "E" : "No Data" }}
            pagination={false}
        >
            <Table.Column
                dataIndex={undefined}
                title={"#"}
                render={(_, __, i) => (viewPage - 1) * viewLimit + (++i)}
            />
            <Table.Column title="Party ID" dataIndex={"partyId"} />
            <Table.Column title="Payment Amount" dataIndex={"amount"} />
            {/*<Table.Column title="Start Date" dataIndex={"start"} />*/}
            <Table.Column
                title="Payment Date"
                dataIndex="start"
                render={value => dayjs(value).format("MMM D, YYYY - hh:mm A")}
            />
            <Table.Column
                title="Current Balance"
                render={(value, record) => (
                    <div style={{ color: 'green' }}>
                        <CurrentBalanceFetcher partyId={record.partyId} />
                    </div>
                )}/>
            {/*<Table.Column title="Date" dataIndex={"date"} render={value => dayjs(value).format("MMM D, YYYY - hh:mm A")} />*/}
        </Table>)
    }
    </>);
};

const DataPager = ({ totalPagingItems, currentPage, onPagingChange }) => {
    return (<>
        <Space align="end" direction="vertical" style={{ width: "100%" }}>
            <Pagination
                total={totalPagingItems}
                defaultPageSize={10}
                pageSizeOptions={["10", "20", "50", "100", "200"]}
                showSizeChanger={true}
                onChange={onPagingChange}
                current={currentPage}
            />
        </Space>
    </>);
};

export const TopUpSummary = () => {
    const [lastQuery, setLastQuery] = useState({});
    const [paymentSummary,  setPaymentSummary] = useState([]);
    const [paymentCount, setPaymentCount] = useState(0);
    const [partyFetchError, setPartyFetchError] = useState(null);

    const {Title} = Typography;
    const [writeForm] = Form.useForm();

    const [modalData, setModalData] = useState(null);
    const showModal = data => setModalData(data);



    useEffect(() => {
        AccountingService.fetchPaymentSummary(lastQuery)
            .then((data) => {
                setPaymentSummary(data.paymentSummary);
                setPaymentCount(data.count);
                setPartyFetchError(null)
            })
            .catch(error => {
                setPaymentSummary([]);
                setPaymentCount(0);
                setPartyFetchError(error);
            });
    }, [lastQuery]);

    useEffect(() => {
        setLastQuery({ page: 1, limit: 10 })
    }, []);

    const CurrentBalanceFetcher = ({ partyId }) => {
        const [balance, setBalance] = useState('0.00');

        useEffect(() => {
            const fetchBalanceData = async () => {
                try {
                    const data = await PartyService.fetchBalance({ partyId });
                    const balanceValue = parseFloat(data.balance).toFixed(2);
                    setBalance(balanceValue);
                } catch (error) {
                    console.error('Error fetching balance:', error);
                    setBalance('0.00');
                }
            };

            fetchBalanceData();
        }, [partyId]);

        return <span>{balance !== null ? balance : 'Loading...'}</span>;
    };

    return (<>
        <Row style={{marginLeft: 5}}>
            <Col md={24}>
                <Card title={<Title level={5}>Summary</Title>}
                      headStyle={{backgroundColor: "#f0f2f5", border: 0, padding: '0px'}}
                      style={{height: 135}} size="small">
                    <SearchForm onSearch={data => setLastQuery({ ...(data || {}), page: 1, limit: lastQuery.limit, orderBy: lastQuery.orderBy })}/>
                </Card>
            </Col>
            <h2>
                {lastQuery.partyId ? (
                    <span>
            <span style={{ color: 'black',marginTop: '20px', marginBottom: '15px'}}>Balance: </span>
            <span style={{ color: 'green', marginTop: '20px', marginBottom: '15px' }}>
                <CurrentBalanceFetcher partyId={lastQuery.partyId} />
            </span>
        </span>
                ) : (
                    ""
                )}
            </h2>
        </Row>
        <DataView paymentSummary={paymentSummary} viewLimit={lastQuery.limit} viewPage={lastQuery.page} partyId={lastQuery.partyId} onEdit={showModal}/>
        <DataPager totalPagingItems={paymentCount} currentPage={lastQuery.page}
                   onPagingChange={(page, limit) => setLastQuery({ ...lastQuery, page, limit })} />
    </>);
};